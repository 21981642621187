import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import SEO from '../components/seo'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { SlugLink } from '../components/utils'

class BlogIndex extends React.Component {
    renderTags(tags) {
        tags.map((item) => {
            //console.log(item)
            return <Button variant="outline-secondary">{item}</Button>
        })
    }
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const siteDescription = data.site.siteMetadata.description
        const domainName = data.site.siteMetadata.domainName
        const posts = data.allMarkdownRemark.edges
        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
        const nextPage = (currentPage + 1).toString()
        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={`${siteTitle} ${currentPage === 1 ? '' : `- Trang ${currentPage}`}`}
                    description={`${siteDescription} ${currentPage === 1 ? '' : `- Trang ${currentPage}`}`}
                    canonical={`https://${domainName}`}
                />
                {posts.map(({ node }) => {
                    let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                        <div key={node.fields.slug}>
                            <Card className="cardPost">
                                <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <Img fluid={featuredImgFluid} objectFit="cover"
                                                objectPosition="50% 50%"
                                                alt={title} title={title} />
                                        </Col>
                                        <Col xs={12} md={8}>
                                            {node.frontmatter.category !== null ? <Link style={{ color: 'rgba(0, 0, 0, .44)', }} to={`/category/${SlugLink(node.frontmatter.category)}`}>{node.frontmatter.category}</Link> : null}
                                            <h2>{title}</h2>
                                            <small style={{ color: '#000' }}>{node.frontmatter.date}</small>  <small style={{ color: 'rgba(0, 0, 0, .44)', }}>| {node.timeToRead} min read</small>
                                            <p className="pdesc" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                        </Col>
                                    </Row>
                                </Link>
                            </Card>
                        </div>
                    )
                })}
                <ul
                    className="pagi"
                >
                    {!isFirst && (
                        <li>
                            <Link to={`/page/${prevPage}`} rel="prev">
                                <Button>
                                    ← Previous Page
                                </Button>
                            </Link>
                        </li>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                        <li
                            key={`pagination-number${i + 1}`}
                        >
                            <Link
                                to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                                style={{
                                    textDecoration: 'none',
                                    color: i + 1 === currentPage ? '#ffffff' : '',
                                    background: i + 1 === currentPage ? '#007acc' : '',
                                }}
                            >
                                <Button>
                                    {i + 1}
                                </Button>
                            </Link>
                        </li>
                    ))}
                    {!isLast && (
                        <li>
                            <Link to={`/page/${nextPage}`} rel="next">
                                <Button>
                                    Next Page →
                            </Button>
                            </Link>
                        </li>
                    )}
                </ul>
            </Layout >
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
query blogPageQuery($skip: Int!, $limit: Int!, $curdate: Date) {
    site {
      siteMetadata {
        title
        domainName
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {frontmatter: {date: {lt: $curdate}}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
          html
          timeToRead
        }
      }
    }
  }
`
